import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Placeholder,
} from "reactstrap";
import api from "../../services/api";

const AdicionaViewDropdown = (props) => {
  let params = useParams();
  let quadroId = params.quadroId;

  const { tipos, setRecebeTipos } = props;
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)}>
        <DropdownToggle nav caret>
          <i className="bx bx-plus font-size-14 text-dark" />
        </DropdownToggle>
        <DropdownMenu style={{ margin: 0 }}>
          {tipos ? (
            tipos.map((tipo) => (
              <DropdownItem
                key={tipo.id}
                onClick={() => {
                  const token = localStorage.getItem("tokenAS3AgileBoard");
                  let viewTipoId = tipo.id;
                  api
                    .post(
                      `/view`,
                      { quadroId, viewTipoId },
                      {
                        headers: { Authorization: token },
                      }
                    )
                    .then((response) => {
                      setRecebeTipos(response.data.tipos);
                    })
                    .catch((err) => {});
                }}
              >
                {tipo.nome}
              </DropdownItem>
            ))
          ) : (
            <Placeholder color="light" />
          )}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default AdicionaViewDropdown;
