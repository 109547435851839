import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import api from "../../services/api";

// import images
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import as3logo from "../../assets/images/as3-engenharia-menor-2.png";

export default function RecuperarSenha() {
  const [usuario, setUsuario] = useState({ email: "" });

  function validateForm() {
    return usuario.email.length > 0;
  }

  function onChange(event) {
    const { value, name } = event.target;

    setUsuario({
      ...usuario,
      [name]: value,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    localStorage.clear();
    try {
      await api.post("/recuperar-senha", usuario).then((response) => {});
    } catch (e) {}
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Recuperação de senha</h5>
                        <p>Recupere a senha da sua conta</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* Logo do AS3 Agile Board */}
                  <div className="auth-logo">
                    <div className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="p-2">
                    <Form className="form-horizontal" onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <Label for="email">E-mail</Label>
                        <Input
                          name="email"
                          type="email"
                          placeholder="Insira o seu e-mail"
                          className="form-control"
                          onChange={onChange}
                          required
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <Button
                          className="btn btn-primary btn-block"
                          size="lg"
                          type="submit"
                          disabled={!validateForm()}
                        >
                          Recuperar
                        </Button>
                      </div>

                      <div className="mt-4 text-center">
                        <p>
                          <i class="bx bxs-lock" /> Lembrou da senha?{" "}
                          <Link
                            to="/login"
                            className="font-weight-medium text-primary"
                          >
                            Clique aqui
                          </Link>{" "}
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className=" text-center">
                <p>
                  © {new Date().getFullYear()} AS3 Agile Board. Feito com{" "}
                  <i className="mdi mdi-heart text-danger" /> e{" "}
                  <i className="mdi mdi-coffee text-brown" /> por{" "}
                  <a
                    href="https://as3engenharia.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={as3logo}
                      alt="AS3 Engenharia"
                      title="AS3 Engenharia"
                      height="30"
                    />
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
