import React from "react";

import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/as3-agile-board.png";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import defaultUserPhoto from "./../../assets/images/no-photo-profile.png";
const Sidebar = (props) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logo} alt="" height="60" />
            </span>
          </Link>
        </div>
        <SidebarContent />
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
