import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Input,
  Form,
  Label,
  Modal,
  ModalBody,
  Row,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import api from "../../../services/api";

const NovoQuadroModal = (props) => {
  //declaração de variáveis
  const { isOpen, toggle } = props;

  let params = useParams();

  const navigate = useNavigate();

  let workspaceId = params.workspaceId;

  const [nome, setNome] = useState("Novo Quadro");

  // handleValidSubmit para enviar o formulário via requisição post para micromeros-back
  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .post(
        `/quadros`,
        { nome, workspaceId },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        toggle();
        navigate(
          `/workspaces/` + workspaceId + `/quadros/` + response.data.quadro.id
        );
      })
      .catch((err) => {});
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Adicionar novo quadro</ModalHeader>
      <Form className="m-3" onSubmit={onSubmit}>
        <ModalBody>
          <Row className="mt-3">
            <Col>
              <Label>Nome do quadro</Label>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="d-flex justify-content-center">
              <Input
                id="input-nome-novo-quadro"
                type="text"
                defaultValue={"Novo quadro"}
                placeholder="Novo quadro"
                onClick={(event) =>
                  document.getElementById("input-nome-novo-quadro").select()
                }
                onChange={(event) => {
                  setNome(event.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            Criar Quadro
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

NovoQuadroModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default NovoQuadroModal;
