import React, { useState } from "react";

import {
  Button,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import NovoWorkspaceModal from "../../components/Common/Modais/NovoWorkspaceModal";

function MenuDashboard() {
  const [abreNovoWorkspaceModal, setAbreNovoWorkspaceModal] = useState(false);

  function toggleAbreNovoWorkspaceModal() {
    setAbreNovoWorkspaceModal(!abreNovoWorkspaceModal);
  }

  return (
    <CardBody className="border-bottom mb-0">
      <div className="d-flex align-items-center justify-content-end">
        <div className="flex-shrink-0">
          <Button
            className=" me-1"
            color="primary"
            onClick={toggleAbreNovoWorkspaceModal}
          >
            + Nova área de trabalho
          </Button>
          <NovoWorkspaceModal
            isOpen={abreNovoWorkspaceModal}
            toggle={toggleAbreNovoWorkspaceModal}
          />
          {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
            <DropdownToggle
              type="menu"
              className="btn btn-light"
              id="dropdownMenuButton1"
            >
              <i className="mdi mdi-dots-vertical"></i>
            </DropdownToggle>
            <DropdownMenu>
              <li>
                <DropdownItem href="#">+ Adicionar</DropdownItem>
              </li>
              <li>
                <DropdownItem href="#">Another action</DropdownItem>
              </li>
              <li>
                <DropdownItem href="#">Something else here</DropdownItem>
              </li>
            </DropdownMenu>
          </UncontrolledDropdown> */}
        </div>
      </div>
    </CardBody>
  );
}

export default MenuDashboard;
