import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../services/api";

const ExcluirWorkspaceModal = (props) => {
  const navigate = useNavigate();
  let params = useParams();

  const { isOpen, toggle } = props;

  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .delete(`/workspaces/${params.workspaceId}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        toggle();
        navigate(`/`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="modal-dialog-centered"
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Excluir área de trabalho</ModalHeader>
        <Form className="m-3" onSubmit={onSubmit}>
          <ModalBody>
            <p className="font-size-14">
              Deseja excluir essa área de trabalho?
            </p>
          </ModalBody>

          <ModalFooter>
            <Button color="link" onClick={toggle}>
              Cancelar
            </Button>
            <Button color="primary" type="submit">
              Excluir área de trabalho
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

ExcluirWorkspaceModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ExcluirWorkspaceModal;
