import React from "react";
import { ProSidebarProvider } from "react-pro-sidebar";

import Aplication from "./routes";

// Import scss
import "./assets/scss/theme.scss";
import "./App.css";

const App = () => (
  <ProSidebarProvider>
    <Aplication />
  </ProSidebarProvider>
);

export default App;
