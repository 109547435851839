import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import MetisMenu from "metismenujs";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
import defaultUserPhoto from "./../../assets/images/no-photo-profile.png";
import { Link, useLocation } from "react-router-dom";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

const SidebarContent = (props) => {
  const ref = useRef();

  const pathName = useLocation().pathname;

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  useEffect(() => {
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.add("mm-show");
        }

        if (parent) {
          parent.classList.add("mm-active");
          const parent2 = parent.parentElement;

          if (parent2) {
            parent2.classList.add("mm-show"); // ul tag

            const parent3 = parent2.parentElement; // li tag

            if (parent3) {
              parent3.classList.add("mm-active"); // li
              parent3.childNodes[0].classList.add("mm-active"); //a
              const parent4 = parent3.parentElement; // ul
              if (parent4) {
                parent4.classList.add("mm-show"); // ul
                const parent5 = parent4.parentElement;
                if (parent5) {
                  parent5.classList.add("mm-show"); // li
                  parent5.childNodes[0].classList.add("mm-active"); // a tag
                }
              }
            }
          }
          scrollElement(item);
          return false;
        }
        scrollElement(item);
        return false;
      }

      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [pathName]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/">
                <i className="bx bx-grid-alt" />
                <span>{"Dashboard"}</span>
              </Link>
            </li>
            {/* MONITORAMENTO
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-bar-chart-alt-2" />
                <span>{"Monitoramento"}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/eventos">{"Lista de eventos"}</Link>
                </li>
                <li>
                  <Link to="/ativos">{"Ativos"}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-file" />
                <span>{"Relatórios"}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/relatorios/comparativo">{"Comparativo"}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-cog" />
                <span>{"Gerenciamento"}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/gerenciamento/gatilhos">{"Gatilhos"}</Link>
                </li>
                <li>
                  <Link to="/gerenciamento/grupos-de-ativos">
                    {"Grupo de ativos"}
                  </Link>
                </li>
                <li>
                  <Link to="/gerenciamento/grupos-de-usuarios">
                    {"Grupo de usuários"}
                  </Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
        <div className="footer-sidebar">
          <UncontrolledDropdown direction="end">
            <DropdownToggle className="btn header-item" tag="button">
              <img
                src={defaultUserPhoto}
                className="rounded-circle header-profile-user"
              />
            </DropdownToggle>
            <DropdownMenu className="footer-profile-menu" container="body">
              <DropdownItem header className="font-size-12">
                Conta
              </DropdownItem>
              <DropdownItem className="font-size-14">
                <Link to="/profile" className="text-dark">
                  <i className="bx bx-user me-2" />
                  Meu perfil
                </Link>
              </DropdownItem>
              <DropdownItem className="font-size-14">
                <Link to="/logout" className="text-dark">
                  <i className="bx bx-exit me-2" />
                  Sair
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default SidebarContent;
