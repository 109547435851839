import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Col, Container, Input, Row } from "reactstrap";
import api from "../../services/api";
import "./styles.css";
import as3logo from "../../assets/images/as3-engenharia-menor-2.png";

export default function Cadastro() {
  const [usuario, setUsuario] = useState({
    nome: "",
    sobrenome: "",
    email: "",
    senha: "",
    isAdmin: false,
    isSuperAdmin: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [desabilitaBotao, setDesabilitaBotao] = useState(false);
  const [erro, setErro] = useState(null);

  function validateForm() {
    return usuario.email.length > 0 && usuario.senha.length > 0;
  }

  function onChange(event) {
    setErro(null);
    const { value, name } = event.target;

    setUsuario({
      ...usuario,
      [name]: value,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setDesabilitaBotao(true);
    try {
      await api.post("/cadastro", usuario).then((response) => {
        setLoading(false);
        setDesabilitaBotao(false);
      });
    } catch (e) {
      if (e.response.data.message_error) {
        setErro(e.response.data.mensagem_erro);
      }
      setLoading(false);
      setDesabilitaBotao(false);
    }
  }

  const handleClick = () => {
    setLoading(true);
  };

  document.title = "Cadastro | AS3 Agile Board - AS3 Engenharia Elétrica RJ";

  return (
    <React.Fragment>
      <header className="header-login">
        <img
          className="header-login-logo"
          src={as3logo}
          alt="AS3 Engenharia"
          title="AS3 Engenharia"
          height="55"
        />
      </header>
      <div className="account-pages my-3 pt-sm-3">
        <Container>
          <Row className="justify-content-center mt-5">
            <Col md={8} lg={6} xl={5}>
              <h2 className="text-dark text-center mt-4">
                Finalize o cadastro da conta
              </h2>
              {erro ? (
                <p className="text-danger text-center mb-n2">{erro}</p>
              ) : null}
              <div className="p-3">
                <Form className="form-horizontal" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <Input
                      name="nome"
                      type="text"
                      placeholder="Nome"
                      className="form-control"
                      onChange={onChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <Input
                      name="sobrenome"
                      type="text"
                      placeholder="Sobrenome"
                      className="form-control"
                      onChange={onChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <Input
                      name="email"
                      type="email"
                      placeholder="E-mail"
                      className="form-control"
                      onChange={onChange}
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <Input
                      name="senha"
                      type="text"
                      className="form-control"
                      placeholder="Senha"
                      onChange={onChange}
                      required
                    />
                  </div>

                  <div className="row justify-content-center gap-5">
                    <div className="col col-3 form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="isAdminCadastroCheckbox"
                        name="isAdmin"
                        onChange={onChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isAdminCadastroCheckbox"
                      >
                        Administrador
                      </label>
                    </div>

                    <div className="col col-3 form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="isSuperAdminCadastroCheckbox"
                        name="isSuperAdmin"
                        onChange={onChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isSuperAdminCadastroCheckbox"
                      >
                        Programador
                      </label>
                    </div>
                  </div>

                  <div className="mt-3 mb-3 d-grid">
                    <Button
                      className="btn btn-primary btn-block"
                      size="lg"
                      type="submit"
                      disabled={!validateForm() || desabilitaBotao}
                      onClick={!isLoading ? handleClick : null}
                    >
                      {isLoading ? "Carregando…" : "Cadastrar"}
                    </Button>
                  </div>
                </Form>
              </div>

              <div className=" text-center">
                <p>
                  © {new Date().getFullYear()} AS3 Agile Board. Feito com{" "}
                  <i className="mdi mdi-heart text-danger" /> e{" "}
                  <i className="mdi mdi-coffee text-brown" /> por{" "}
                  <a
                    href="https://as3engenharia.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={as3logo}
                      alt="AS3 Engenharia"
                      title="AS3 Engenharia"
                      height="30"
                    />
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
