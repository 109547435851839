import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Col, Container, Input, Row } from "reactstrap";
import api from "../../services/api";
import "./styles.css";
import as3logo from "../../assets/images/as3-engenharia-menor-2.png";

export default function Login() {
  const [usuario, setUsuario] = useState({ email: "", senha: "" });
  const [isLoading, setLoading] = useState(false);
  const [desabilitaBotao, setDesabilitaBotao] = useState(false);
  const [erroContaBloqueada, setErroContaBloqueada] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  function validateForm() {
    return usuario.email.length > 0 && usuario.senha.length > 0;
  }

  function onChange(event) {
    const { value, name } = event.target;

    setUsuario({
      ...usuario,
      [name]: value,
    });
  }

  async function handleSubmit(event) {
    setDesabilitaBotao(true);
    event.preventDefault();
    try {
      await api.post("/login", usuario).then((response) => {
        localStorage.setItem(
          "authUserAS3AgileBoard",
          JSON.stringify(response.data.usuario)
        );
        localStorage.setItem("userEmailAS3AgileBoard", response.data.email);
        localStorage.setItem("tokenAS3AgileBoard", response.data.token);

        navigate("/");
      });
    } catch (e) {
      setDesabilitaBotao(false);
      setLoading(false);
      setErroContaBloqueada("");
      // if (e.response.data.mensagem_bloqueio) {
      //   setErroContaBloqueada(e.response.data.mensagem_bloqueio);
      // }
    }
  }

  const handleClick = () => {
    setLoading(true);
    setEmail(usuario.email);
  };

  function handleEnvioEmail() {
    api
      .post(`reenviar-email-desbloqueio`, { email })
      .then((response) => {})
      .catch((error) => {});
  }

  return (
    <React.Fragment>
      <header className="header-login">
        <img
          className="header-login-logo"
          src={as3logo}
          alt="AS3 Engenharia"
          title="AS3 Engenharia"
          height="55"
        />
      </header>
      <div className="account-pages my-3 pt-sm-3">
        <Container>
          <Row className="justify-content-center mt-5">
            <Col md={8} lg={6} xl={5}>
              <h2 className="text-dark text-center mt-5">
                Faça login em sua conta
              </h2>

              {erroContaBloqueada ? (
                <>
                  <span className="text-danger">{erroContaBloqueada} </span>
                  <button
                    color="link"
                    className="btn btn-link btn-rounded p-1 mt-n2 ms-n1"
                    onClick={handleEnvioEmail}
                  >
                    Enviar novamente?
                  </button>
                </>
              ) : null}
              <div className="p-3">
                <Form className="form-horizontal" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <Input
                      name="email"
                      type="email"
                      placeholder="E-mail"
                      className="form-control"
                      onChange={onChange}
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <Input
                      name="senha"
                      type="password"
                      className="form-control"
                      placeholder="Senha"
                      onChange={onChange}
                      required
                    />
                  </div>

                  {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Lembrar-me
                        </label>
                      </div> */}

                  <div className="mt-3 d-grid">
                    <Button
                      className="btn btn-primary btn-block"
                      size="lg"
                      type="submit"
                      disabled={!validateForm() || desabilitaBotao}
                      onClick={!isLoading ? handleClick : null}
                    >
                      {isLoading ? "Carregando…" : "Login"}
                    </Button>
                  </div>

                  <div className="mt-4 text-center">
                    <Link to="/recuperar-senha" className="text-muted">
                      <i className="mdi mdi-lock me-1" />
                      Esqueceu sua senha?
                    </Link>
                  </div>
                </Form>
              </div>

              <div className=" text-center">
                <p>
                  © {new Date().getFullYear()} AS3 Agile Board. Feito com{" "}
                  <i className="mdi mdi-heart text-danger" /> e{" "}
                  <i className="mdi mdi-coffee text-brown" /> por{" "}
                  <a
                    href="https://as3engenharia.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={as3logo}
                      alt="AS3 Engenharia"
                      title="AS3 Engenharia"
                      height="30"
                    />
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
