import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  Row,
  Input,
  CardTitle,
} from "reactstrap";
import api from "../../services/api";
import "./styles.css";

const DescricaoQuadroModal = (props) => {
  //declaração de variáveis
  const { isOpen, toggle, descricao, setDescricao } = props;

  let params = useParams();

  let quadroId = params.quadroId;

  const [quadro, setQuadro] = useState(null);
  const [novaDescricao, setNovaDescricao] = useState("");

  const fraseDefault =
    "Adicione aqui uma descrição para garantir que sua equipe fique alinhada em relação à finalidade deste quadro.";

  useEffect(() => {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .get(`/quadros/` + quadroId + `/descricao`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setQuadro(response.data.descricao);
      })
      .catch((err) => {});
  }, [params.quadroId]);

  function onSubmit() {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .post(
        `/quadros/` + quadroId + `/descricao`,
        { novaDescricao },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        setDescricao(response.data.descricao);
      })
      .catch((err) => {});
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      toggle={toggle}
      size="lg"
    >
      <div style={{ display: "flex", height: "100%" }}>
        {/* fechar modal */}
        <div
          role="button"
          aria-label="Close"
          style={{
            position: "absolute",
            top: "6px",
            right: "8px",
          }}
          onClick={toggle}
        >
          <i className="bx bx-x font-size-24" />
        </div>

        {/* lado esquerdo */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "auto",
            position: "relative",
            padding: "20px 32px 32px 32px",
            width: "62%",
          }}
        >
          <div>
            {/* quadro nome / placeholder */}
            <div className="mb-3">
              {quadro ? (
                <CardTitle style={{ fontSize: "20px" }}>
                  {quadro.quadro_nome}
                </CardTitle>
              ) : (
                <p className="placeholder-glow font-size-20">
                  <span className="placeholder col-6"></span>
                </p>
              )}
            </div>

            {/* descricao */}
            <Input
              type="textarea"
              rows={15}
              maxLength={690}
              placeholder={fraseDefault}
              defaultValue={descricao}
              style={{
                overflow: "scroll",
                maxHeight: "400px",
                minHeight: "150px",
              }}
              onChange={(event) => {
                setNovaDescricao(event.target.value);
              }}
              onKeyUp={onSubmit}
            />
          </div>
        </div>

        {/* lado direito */}
        <div
          style={{
            padding: "26px 32px 32px 32px",
            width: "38%",
            flex: "initial",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#E3E4E5",
            borderRadius: "0 5px 5px 0",
            border: "none",
          }}
        >
          <CardTitle className="mb-3 text-truncate">
            Informações do quadro
          </CardTitle>

          <Row>
            <Label className="text-truncate">Área de trabalho</Label>
          </Row>

          {quadro ? (
            <Row className="mb-3">
              {" "}
              <div className="d-inline-flex align-items-center">
                {/* ícone */}
                <div
                  role="image"
                  style={{
                    backgroundColor: quadro.workspace_cor_hexa,
                    width: "24px",
                    height: "24px",
                    borderRadius: "8px",
                    marginRight: "6px",
                  }}
                >
                  <div
                    className={
                      "d-flex justify-content-center align-items-center font-size-16 text-white"
                    }
                    style={{ fontWeight: "500" }}
                  >
                    {quadro.workspace_inicial}
                  </div>
                </div>

                <span className="font-size-14 text-truncate">
                  {quadro.workspace_nome}
                </span>
              </div>
            </Row>
          ) : (
            <p className="placeholder-glow font-size-14">
              <span className="placeholder col-12"></span>
            </p>
          )}

          <Row>
            <Label>Criado por</Label>
          </Row>

          {quadro ? (
            <Row className="mb-3">
              <div className="font-size-14 ">
                <span className="me-2">{quadro.quadro_criado_por}</span>
                {new Date(quadro.created_at).toLocaleString("PT-BR")}
              </div>
            </Row>
          ) : (
            <p className="placeholder-glow font-size-14">
              <span className="placeholder col-12"></span>
            </p>
          )}

          <Row>
            <Label>Proprietários</Label>
          </Row>
          {quadro ? (
            <Row className="mb-3">
              {quadro.proprietarios.map((proprietario) => (
                <div key={proprietario.id} className="font-size-14 ">
                  {proprietario.nome}
                </div>
              ))}
            </Row>
          ) : (
            <p className="placeholder-glow font-size-14">
              <span className="placeholder col-12"></span>
            </p>
          )}

          <Row>
            <Label>Tipo de quadro</Label>
          </Row>

          {quadro ? (
            <Row className="mb-3">
              <div className="font-size-14">{quadro.privacidade}</div>
            </Row>
          ) : (
            <p className="placeholder-glow font-size-14">
              <span className="placeholder col-12"></span>
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

DescricaoQuadroModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  descricao: PropTypes.string,
  setDescricao: PropTypes.func,
};

export default DescricaoQuadroModal;
