import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Form,
  Label,
  Modal,
  ModalBody,
  Row,
  ModalHeader,
  ModalFooter,
  UncontrolledDropdown,
} from "reactstrap";
import api from "../../../services/api";

const NovoWorkspaceModal = (props) => {
  const navigate = useNavigate();

  const { isOpen, toggle } = props;

  //lista de cores disponíveis
  const arrayCores = [
    "#DC143C",
    "#3CB371",
    "#4B0082",
    "#008080",
    "#000000",
    "#4682B4",
    "#FF00FF",
    "#FF0000",
    "#FFC222",
    "#FF4500",
    "#808080",
  ];

  // useEffect(() => {
  //   const token = localStorage.getItem("tokenAS3AgileBoard");
  //   api
  //     .get("/cor-hexa", { headers: { Authorization: token } })
  //     .then((response) => {
  //       setArrayCores(response.data.arrayCores);
  //     });
  //   setTimeout(() => {
  //     setCorHexa(indexRandom());
  //   }, 1000);
  // }, []);

  //função para escolher cor de forma aleatória da lista
  function indexRandom() {
    let index = Math.floor(Math.random() * arrayCores.length);
    return arrayCores[index];
  }

  //declaração de variáveis
  const [nome, setNome] = useState("Nova área de trabalho");
  const [inicial, setInicial] = useState("N");
  const [isPrivate, setIsPrivate] = useState(false);
  const [corHexa, setCorHexa] = useState(indexRandom());

  // handleValidSubmit para enviar o formulário via requisição post para micromeros-back
  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .post(
        `/workspaces`,
        { nome, isPrivate, corHexa },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        toggle();
        navigate(`/workspaces/` + response.data.workspace.id);
      })
      .catch((err) => {});
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Adicionar nova área de trabalho</ModalHeader>
      <Form className="m-3" onSubmit={onSubmit}>
        <ModalBody>
          <Row>
            <Col className="d-flex justify-content-center">
              <UncontrolledDropdown className="dropdown d-inline-block me-1">
                <DropdownToggle data-toggle="dropdown" tag="span">
                  <div
                    role="button"
                    className="workspace-icone-novo-workspace-container"
                  >
                    <i className="fas fa-pencil-alt" />
                    <span>Editar</span>
                  </div>
                  <div
                    role="img"
                    className="workspace-icone-novo-workspace text-white"
                    style={{ backgroundColor: corHexa }}
                  >
                    {inicial}
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem text>Cor de fundo</DropdownItem>
                  <div className="color-items-wrapper">
                    {arrayCores.map((cor, key) => (
                      <button
                        key={key}
                        style={{ backgroundColor: cor }}
                        className="color-item"
                        onClick={(event) => {
                          event.preventDefault();
                          setCorHexa(cor);
                        }}
                      ></button>
                    ))}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label>Nome da área de trabalho</Label>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="d-flex justify-content-center">
              <Input
                id="input-nome-novo-workspace"
                type="text"
                defaultValue={"Nova área de trabalho"}
                placeholder="Nova área de trabalho"
                maxLength="30"
                onClick={(event) =>
                  document.getElementById("input-nome-novo-workspace").select()
                }
                onChange={(event) => {
                  var str = event.target.value;
                  setNome(str);
                  if (str.length > 0) {
                    setInicial(str.substring(0, 1).toUpperCase());
                  } else {
                    setInicial("N");
                  }
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Privacidade</Label>
            </Col>
          </Row>

          <Row className="d-flex align-content-center gap-1">
            <Col lg="3">
              <Input
                name="privacidade"
                id="checkbox-privacidade-nova-workspace"
                type="checkbox"
                onChange={(event) => {
                  setIsPrivate(event.target.checked);
                }}
              />{" "}
              <Label check>Privado</Label>
            </Col>
          </Row>
          <Row>
            {isPrivate ? (
              <p>Somente os membros convidados podem participar</p>
            ) : (
              <p>Todos da AS3 Engenharia podem participar</p>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            Adicionar área de trabalho
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

NovoWorkspaceModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default NovoWorkspaceModal;
