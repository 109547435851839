import React, { useState } from "react";
import api from "../../services/api";
import { Link, useParams } from "react-router-dom";
import AutosizeInput from "react-input-autosize";
import NovoQuadroModal from "./../../components/Common/Modais/NovoQuadroModal";
import {
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Col,
  Row,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  CardTitle,
} from "reactstrap";
import DescricaoQuadroModal from "./DescricaoQuadroModal";

export default function HeaderQuadro(props) {
  let params = useParams();
  let quadroId = params.quadroId;

  const {
    nomeQuadro,
    setNomeQuadro,
    descricaoQuadro,
    setDescricaoQuadro,
    abreDescricaoQuadroModal,
    setAbreDescricaoQuadroModal,
  } = props;

  const [novoNomeQuadro, setNovoNomeQuadro] = useState(null);

  const [abreAtividadeQuadroOffCanvas, setAbreAtividadeQuadroOffCanvas] =
    useState(false);

  const [abreConvidarMembrosModal, setAbreConvidarMembrosModal] =
    useState(false);

  const [abreNovoQuadroModal, setAbreNovoQuadroModal] = useState(false);

  const [abrePermissoesQuadroModal, setAbrePermissoesQuadroModal] =
    useState(false);

  function toggleAbreDescricaoQuadroModal() {
    setAbreDescricaoQuadroModal(!abreDescricaoQuadroModal);
  }

  function toggleAbreAtividadeQuadroOffCanvas() {
    setAbreAtividadeQuadroOffCanvas(!abreAtividadeQuadroOffCanvas);
  }

  function toggleConvidarMembrosQuadro() {
    setAbreConvidarMembrosModal(!abreConvidarMembrosModal);
  }

  function toggleAbreNovoQuadroModal() {
    setAbreNovoQuadroModal(!abreNovoQuadroModal);
  }

  function toggleAbrePermissoesQuadroModal() {
    setAbrePermissoesQuadroModal(!abrePermissoesQuadroModal);
  }

  function alteraNomeQuadro() {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .post(
        `/quadros/` + quadroId + `/nome`,
        { novoNomeQuadro },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        setNomeQuadro(response.data.nome);
      })
      .catch((err) => {});
  }
  return (
    <>
      <Row className="align-items-center mb-2">
        <Col xl={6} sm={6}>
          <Row className="d-flex g-2 align-items-center float-left">
            {nomeQuadro !== null ? (
              <div className="col-auto">
                {/* Nome do quadro */}
                <AutosizeInput
                  className="nome-quadro input-sem-borda"
                  maxLength={29}
                  defaultValue={nomeQuadro}
                  inputStyle={{
                    border: "1px solid #FFFFFF",
                    borderRadius: 3,
                    fontSize: "25px",
                    fontWeight: 500,
                  }}
                  onChange={(event) => {
                    setNovoNomeQuadro(event.target.value);
                  }}
                  onKeyUp={alteraNomeQuadro}
                />
              </div>
            ) : (
              <div className="col-6">
                <p className="placeholder-glow font-size-24">
                  <span className="placeholder col-12"></span>
                </p>
              </div>
            )}

            {/* informações do quadro */}
            <div className="col-auto">
              <Link to="#" onClick={toggleAbreDescricaoQuadroModal}>
                <i className=" bx bx-info-circle font-size-20 text-dark mt-n2" />
              </Link>
            </div>
            <div className="col-auto">
              <i className=" bx bx-star font-size-20 text-dark mt-n3" />
            </div>
          </Row>
          <DescricaoQuadroModal
            isOpen={abreDescricaoQuadroModal}
            toggle={toggleAbreDescricaoQuadroModal}
            descricao={descricaoQuadro}
            setDescricao={setDescricaoQuadro}
          />
        </Col>

        {/* Segunda coluna */}
        <Col xl={6} sm={6}>
          <Row className="d-inline-flex align-content-center gap-2 float-end">
            {/* Atividade do quadro */}
            <div className="col-auto">
              <Button
                id="atividade-quadro"
                className="botao-branco"
                color="ffffff"
                onClick={toggleAbreAtividadeQuadroOffCanvas}
              >
                <i className="bx bx-notepad font-size-20" />
              </Button>
              <Offcanvas
                isOpen={abreAtividadeQuadroOffCanvas}
                direction="end"
                toggle={toggleAbreAtividadeQuadroOffCanvas}
                style={{ width: "calc(70% - 200px)" }}
              >
                <OffcanvasHeader toggle={toggleAbreAtividadeQuadroOffCanvas}>
                  <span className="font-size-20">
                    <strong>{nomeQuadro}</strong> Log
                  </span>
                </OffcanvasHeader>
                <OffcanvasBody></OffcanvasBody>
              </Offcanvas>
              <UncontrolledTooltip placement="bottom" target="atividade-quadro">
                Atividade do quadro
              </UncontrolledTooltip>
            </div>

            {/*Convidar membros */}
            <div className="col-auto">
              <Button
                id="convidar-membros-quadro"
                onClick={toggleConvidarMembrosQuadro}
                color="ffffff"
                className="botao-branco"
              >
                <span className="d-inline-flex font-size-14">
                  <i className=" bx bx-user-plus font-size-20 me-1" />
                  Convidar
                </span>
              </Button>
            </div>
            <Modal
              isOpen={abreConvidarMembrosModal}
              toggle={toggleConvidarMembrosQuadro}
              centered
            >
              <ModalHeader toggle={toggleConvidarMembrosQuadro}>
                Membros do Quadro
              </ModalHeader>
              <ModalBody>
                <Input
                  type="text"
                  placeholder="Insira o nome ou e-mail"
                  // TODO: Inserir função onChange e enviar input para o back. Retornar listagem de e-mails compatíveis e opção de convidar
                  //caso não tenha nenhum registro
                />
                {/* TODO Mapear membros do quadro */}
              </ModalBody>
            </Modal>

            {/* 3 pontinhos */}
            <div className="col-auto">
              <UncontrolledDropdown className="dropdown d-inline-block me-1">
                <DropdownToggle
                  type="menu"
                  color="ffffff"
                  className="botao-branco"
                  id="dropdownMenuButton1"
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(event) => toggleAbrePermissoesQuadroModal()}
                  >
                    <i className="bx bx-lock" /> Permissões de quadro
                    <Modal
                      isOpen={abrePermissoesQuadroModal}
                      toggle={toggleAbrePermissoesQuadroModal}
                      size="xl"
                      centered
                    >
                      <ModalHeader toggle={toggleAbrePermissoesQuadroModal}>
                        Permissões de quadro
                      </ModalHeader>
                      <ModalBody>
                        <CardTitle>Permissões de visualização</CardTitle>
                      </ModalBody>
                    </Modal>
                  </DropdownItem>
                  <DropdownItem
                    onClick={(event) => toggleAbreNovoQuadroModal()}
                  >
                    <i className="bx bx-plus" /> Adicionar
                  </DropdownItem>
                  <DropdownItem
                    onClick={(event) => toggleAbreNovoQuadroModal()}
                  >
                    <i className="bx bx-dots-horizontal-rounded" /> Mais ações
                  </DropdownItem>
                  <DropdownItem></DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NovoQuadroModal
                isOpen={abreNovoQuadroModal}
                toggle={toggleAbreNovoQuadroModal}
              />
            </div>
          </Row>
        </Col>
      </Row>
      {/* Descrição Quadro e ver mais*/}
      {descricaoQuadro ? (
        <Row className="align-items-center mb-3">
          <Link to="#" onClick={toggleAbreDescricaoQuadroModal}>
            <div className="col-auto">
              <span
                className="text-dark text-truncate"
                style={{ maxWidth: "50%" }}
              >
                {descricaoQuadro}
              </span>
              <span className="ms-1">Ver mais</span>
            </div>
          </Link>
        </Row>
      ) : null}
    </>
  );
}
