import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Loading from "../../components/Common/Loading";
import Layout from "../../components/VerticalLayout";
import api from "../../services/api";

import MenuDashboard from "./MenuDashboard.js";
import "./styles.css";

function Dashboard() {
  const [workspaces, setWorkspaces] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .get("/workspaces", {
        headers: { Authorization: token },
      })
      .then((response) => {
        setWorkspaces(response.data.workspaces);
      })
      .catch((err) => {});
  }, []);

  document.title = "Dashboard | AS3 Agile Board - AS3 Engenharia Elétrica RJ";

  return (
    <Layout>
      <div className="page-content">
        <Container fluid>
          <Card>
            <MenuDashboard />
            <CardBody style={{ minHeight: "400px" }}>
              <CardTitle className="mb-3">Minhas áreas de trabalho</CardTitle>

              {workspaces ? (
                <Row>
                  {workspaces.map((workspace) => (
                    <Col
                      xl={4}
                      sm={12}
                      md={12}
                      className="justify-content-center"
                      key={workspace.id}
                    >
                      <Link
                        to={"/workspaces/" + workspace.id}
                        className="text-dark"
                      >
                        <Card className="border">
                          <CardBody>
                            <div className="d-flex gap-3">
                              <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                <span
                                  className="avatar-title rounded-circle font-size-24"
                                  style={{
                                    backgroundColor: workspace.cor,
                                  }}
                                >
                                  {workspace.inicial}
                                </span>
                              </div>
                              <div className="pt-3 align-self-center">
                                <h5 className="mb-0">{workspace.nome}</h5>
                                <p className="text-muted fw-medium">
                                  {workspace.quadros +
                                    " | " +
                                    workspace.paineis}
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  ))}
                </Row>
              ) : (
                <div
                  style={{
                    widht: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "140px",
                  }}
                >
                  <Loading />
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </Layout>
  );
}

export default Dashboard;
