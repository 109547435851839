import React from "react";
import { Row } from "reactstrap";
import Grupos from "./Grupos";

export default function Tabela(props) {
  const {
    grupos,
    quadroId,
    colunasSubelemento,
    colunasElemento,
    gruposLength,
    setGruposLength,
    elementoControle,
    setElementoControle,
    grupoControle,
    setGrupoControle,
  } = props;

  return (
    <Row className="m-0">
      {grupos.map((grupo, index) => (
        <Grupos
          key={grupo.grupo_id}
          grupo={grupo}
          quadroId={quadroId}
          colunasElemento={colunasElemento}
          colunasSubelemento={colunasSubelemento}
          gruposLength={gruposLength}
          setGruposLength={setGruposLength}
          grupoControle={grupoControle}
          setGrupoControle={setGrupoControle}
          elementoControle={elementoControle}
          setElementoControle={setElementoControle}
        />
      ))}
    </Row>
  );
}
