import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Workspace from "./pages/Workspace";
import Login from "./pages/Authentication/Login";
import Logout from "./pages/Authentication/Logout";

import Pages404 from "./pages/pages-404";
import PrivateRoute from "./services/auth";

import UserProfile from "./pages/Authentication/UserProfile";

import RecuperarSenha from "./pages/Authentication/RecuperarSenha";
import DefinirNovaSenha from "./pages/Authentication/DefinirNovaSenha";

import CriarSenha from "./pages/Authentication/CriarSenha";

import DesbloquearConta from "./pages/Authentication/DesbloquearConta";
import Cadastro from "./pages/Authentication/Cadastro";
import Quadro from "./pages/Quadro";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/criar-senha/:refreshToken"
          element={<CriarSenha />}
        />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/cadastro" element={<Cadastro />} />
        <Route exact path="/recuperar-senha" element={<RecuperarSenha />} />
        <Route
          exact
          path="/redefinir-senha/:refreshToken"
          element={<DefinirNovaSenha />}
        />
        <Route
          exact
          path="/desbloquear-conta/:refreshToken"
          element={<DesbloquearConta />}
        />
        <Route path="*" element={<Pages404 />} />

        <Route element={<PrivateRoute />}>
          <Route exact path="/" element={<Dashboard />} />

          <Route
            exact
            path="/workspaces/:workspaceId/quadros/:quadroId"
            element={<Quadro />}
          />
          <Route
            exact
            path="/workspaces/:workspaceId"
            element={<Workspace />}
          />
          {/*PERFIL */}
          <Route exact path="/profile" element={<UserProfile />} />
          <Route exact path="/logout" element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
