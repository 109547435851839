import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import "./styles.css";

import Layout from "../../components/VerticalLayout";
import api from "../../services/api";
import Loading from "../../components/Common/Loading";
import HeaderQuadro from "./HeaderQuadro";
import ViewsQuadro from "./ViewsQuadro";

function Quadro() {
  let params = useParams();
  let quadroId = params.quadroId;

  //quadro
  const [quadro, setQuadro] = useState(null);
  const [nomeQuadro, setNomeQuadro] = useState(null);
  const [descricaoQuadro, setDescricaoQuadro] = useState(null);
  const [viewsQuadro, setViewsQuadro] = useState(null);
  const [colunasElemento, setColunasElemento] = useState(null);
  const [colunasSubelemento, setColunasSubelemento] = useState(null);
  const [grupos, setGrupos] = useState();
  const [abreDescricaoQuadroModal, setAbreDescricaoQuadroModal] =
    useState(false);
  const [grupoControle, setGrupoControle] = useState(false);
  const [gruposLength, setGruposLength] = useState(0);
  const [elementoControle, setElementoControle] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .get(`/quadros/` + quadroId, {
        headers: { Authorization: token },
      })
      .then((response) => {
        //quadro
        setQuadro(response.data.quadro);
        setNomeQuadro(response.data.quadro.quadro_nome);
        setDescricaoQuadro(response.data.quadro.quadro_descricao);
        setViewsQuadro(response.data.quadro.views);
        setColunasElemento(response.data.quadro.colunasElemento);
        setColunasSubelemento(response.data.quadro.colunasSubelemento);
        setGrupos(response.data.quadro.grupos);
        setGruposLength(response.data.quadro.grupos.length);
      })
      .catch((err) => {});
  }, [
    quadroId,
    abreDescricaoQuadroModal,
    grupoControle,
    gruposLength,
    elementoControle,
  ]);

  return (
    <Layout>
      <div className="page-content">
        <Container fluid>
          {quadro ? (
            <Card style={{ minHeight: "800px" }}>
              <CardBody>
                <>
                  <HeaderQuadro
                    nomeQuadro={nomeQuadro}
                    setNomeQuadro={setNomeQuadro}
                    descricaoQuadro={descricaoQuadro}
                    setDescricaoQuadro={setDescricaoQuadro}
                    abreDescricaoQuadroModal={abreDescricaoQuadroModal}
                    setAbreDescricaoQuadroModal={setAbreDescricaoQuadroModal}
                  />

                  <ViewsQuadro
                    quadroId={quadroId}
                    views={viewsQuadro}
                    colunasElemento={colunasElemento}
                    colunasSubelemento={colunasSubelemento}
                    grupos={grupos}
                    grupoControle={grupoControle}
                    setGrupoControle={setGrupoControle}
                    gruposLength={gruposLength}
                    setGruposLength={setGruposLength}
                    elementoControle={elementoControle}
                    setElementoControle={setElementoControle}
                  />
                </>
              </CardBody>
            </Card>
          ) : (
            <Card
              style={{ minHeight: "700px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <Loading />
            </Card>
          )}
        </Container>
      </div>
    </Layout>
  );
}
export default Quadro;
