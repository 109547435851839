import React from "react";
import { Checkbox, TableCell, TableRow, Collapse } from "@mui/material";
import { Link } from "react-router-dom";
import NoProfileImg from "./../../../assets/images/no-photo-profile.png";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../services/api";
import CelulaNumeros from "./CelulaNumeros";
import Subelementos from "./Subelementos";

export default function Elementos(props) {
  const {
    elemento,
    quadroId,
    colunasSubelemento,
    elementoControle,
    setElementoControle,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [pessoas, setPessoas] = React.useState([]);
  const [etiquetas, setEtiquetas] = React.useState([]);

  function handleExcluirElemento() {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .delete(`/elemento/` + elemento.elemento_id, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setElementoControle(!elementoControle);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  function handleRenomearElemento(event, elementoId, defaultValue) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    if (event.target.value !== "") {
      api
        .post(
          `/elemento/` + elementoId,
          {
            elementoTitulo: event.target.value,
          },
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setElementoControle(!elementoControle);
        });
    } else {
      event.target.value = defaultValue;
    }
  }

  function handlePessoas(arrayPessoas) {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    if (arrayPessoas === "") {
      arrayPessoas = [];
    }
    api
      .post(
        `/pessoas`,
        {
          quadroId,
          arrayPessoas,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setPessoas(response.data.pessoas);
      })
      .catch((e) => {});
  }

  function handleEtiquetas(colunaId) {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .get(`/etiqueta/coluna-elemento/` + colunaId, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setEtiquetas(response.data.etiquetas);
      })
      .catch((e) => {});
  }

  function handleConteudo(colunaId, conteudo) {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .post(
        `/conteudo-elemento`,
        {
          elementoId: elemento.elemento_id,
          quadroColunaElementoId: colunaId,
          conteudo: conteudo,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setElementoControle(!elementoControle);
      })
      .catch((e) => {});
  }

  return (
    <React.Fragment key={elemento.elemento_id}>
      {/* Toasts */}

      <TableRow hover className="row-elementos">
        {/* opções de elemento */}
        <TableCell
          sx={{
            borderLeft: "hidden",
            borderBottom: "hidden",
            borderTop: "hidden",
          }}
          align="center"
        >
          <UncontrolledDropdown>
            <DropdownToggle className="button-elemento-opcoes">
              <i className=" d-flex bx bx-dots-horizontal font-size-14 " />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  handleExcluirElemento();
                }}
              >
                <i className="bx bx-trash me-1" />
                <span>Excluir Elemento</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </TableCell>
        {/* elemento.elemento_id */}
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            inputProps={{
              "aria-label": "selecionar todos",
            }}
          />
        </TableCell>

        {/* elemento.colunasElemento */}
        {elemento.Elemento ? (
          <TableCell align="left" className="d-flex align-items-center">
            {elemento.subelementos.length > 0 ? (
              <Link
                to="#"
                className="toggle-subelemento-button"
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <i className=" d-flex bx bx-chevron-down font-size-22" />
                ) : (
                  <i className=" d-flex bx bx-chevron-right font-size-22" />
                )}
              </Link>
            ) : (
              <Link
                to="#"
                className="toggle-subelemento-button"
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <i className=" d-flex bx bx-chevron-down font-size-22" />
                ) : (
                  <i className=" d-flex bx bx-chevron-right font-size-22" />
                )}
              </Link>
            )}

            <Input
              className="input-sem-borda font-size-14"
              defaultValue={elemento.Elemento}
              maxLength="99"
              onBlur={(event) => {
                handleRenomearElemento(
                  event,
                  elemento.elemento_id,
                  elemento.Elemento
                );
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleRenomearElemento(
                    event,
                    elemento.elemento_id,
                    elemento.Elemento
                  );
                }
              }}
            />
          </TableCell>
        ) : null}

        {/* Pessoa */}
        {elemento.colunas.map((coluna) => (
          <React.Fragment key={coluna.coluna_id}>
            {/* Pessoa */}
            {coluna.coluna_tipo === "Pessoa" ? (
              <TableCell align="center" className="cell-elementos-pessoa">
                <Row className="d-flex align-items-center">
                  <Col lg="auto" className="">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        size="sm"
                        className="button-adicionar-pessoa btn btn-rounded p-0"
                        onClick={() => handlePessoas(coluna.coluna_conteudo)}
                      >
                        <i className="bx bx-plus font-size-16 m-0" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {coluna.coluna_conteudo.length > 0 ? (
                          <>
                            <DropdownItem header>
                              <span>Responsaveis</span>
                            </DropdownItem>
                            {coluna.coluna_conteudo.map(
                              (responsavel, index) => (
                                <React.Fragment key={responsavel.usuario_id}>
                                  <DropdownItem text>
                                    <Row className="d-flex align-items-center justify-content-center gap-2">
                                      <Col>
                                        <div>
                                          {responsavel.nome}{" "}
                                          {responsavel.sobrenome}
                                        </div>
                                      </Col>
                                      <Col>
                                        <Button
                                          size="sm"
                                          className="button-excluir-pessoa btn btn-square font-size-14 text-dark"
                                          onClick={() => {
                                            let conteudo =
                                              coluna.coluna_conteudo;
                                            if (conteudo.length > 0) {
                                              conteudo = conteudo.map(
                                                (usuario) => usuario.usuario_id
                                              );
                                            } else {
                                              conteudo = [];
                                            }
                                            conteudo.splice(index, 1);
                                            handleConteudo(
                                              coluna.coluna_id,
                                              conteudo
                                            );
                                            pessoas.push(responsavel);
                                          }}
                                        >
                                          <i className="bx bx-x font-size-16 m-0" />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </DropdownItem>
                                </React.Fragment>
                              )
                            )}
                          </>
                        ) : null}
                        <DropdownItem header>
                          <span>Pessoas sugeridas</span>
                        </DropdownItem>
                        {pessoas.map((pessoa) => (
                          <React.Fragment key={pessoa.usuario_id}>
                            <DropdownItem
                              onClick={() => {
                                let conteudo = coluna.coluna_conteudo;
                                if (conteudo.length > 0) {
                                  conteudo = conteudo.map(
                                    (usuario) => usuario.usuario_id
                                  );
                                } else {
                                  conteudo = [];
                                }
                                conteudo.push(pessoa.usuario_id);
                                handleConteudo(coluna.coluna_id, conteudo);
                              }}
                            >
                              <span>
                                {pessoa.nome} {pessoa.sobrenome}
                              </span>
                            </DropdownItem>
                          </React.Fragment>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>

                  <Col className="d-flex justify-content-center pe-5">
                    {coluna.coluna_conteudo.length > 0 ? (
                      <div className="avatar-group d-inline-flex">
                        {coluna.coluna_conteudo.map((conteudo, index) => (
                          <React.Fragment key={index}>
                            <>
                              {index < 2 ? (
                                <Link
                                  to="#"
                                  className="  avatar-group-item"
                                  key={index}
                                >
                                  {" "}
                                  <div className="avatar-xs">
                                    <span
                                      className={
                                        "avatar-title rounded-circle text-white font-size-16"
                                      }
                                    >
                                      {conteudo.nome
                                        .substring(0, 1)
                                        .toUpperCase() +
                                        conteudo.sobrenome
                                          .substring(0, 1)
                                          .toUpperCase()}
                                    </span>
                                  </div>
                                </Link>
                              ) : null}
                            </>
                          </React.Fragment>
                        ))}
                        {coluna.coluna_conteudo.length >= 3 ? (
                          <Link to="#" className="  avatar-group-item">
                            {" "}
                            <div className="avatar-xs">
                              <span
                                className={
                                  "avatar-title rounded-circle text-white font-size-16"
                                }
                              >
                                <div>
                                  <span>
                                    +{coluna.coluna_conteudo.length - 2}
                                  </span>
                                </div>
                              </span>
                            </div>
                          </Link>
                        ) : null}
                      </div>
                    ) : (
                      <div className="avatar-group d-inline-flex">
                        <Link to="#" className="  avatar-group-item">
                          {" "}
                          <div className="avatar-xs">
                            <span
                              className={
                                "avatar-title rounded-circle text-white font-size-16"
                              }
                            >
                              <img
                                src={NoProfileImg}
                                className="rounded-circle avatar-xs"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                    )}
                  </Col>
                  {/* */}
                </Row>
              </TableCell>
            ) : null}

            {coluna.coluna_tipo === "Status" ? (
              <TableCell align="center">
                <UncontrolledDropdown className="cell-status">
                  <DropdownToggle
                    style={{ backgroundColor: coluna.coluna_conteudo.cor_hexa }}
                    className="button-coluna-status"
                    data-bs-toggle="dropdown"
                    onClick={() => handleEtiquetas(coluna.coluna_id)}
                  >
                    {coluna.coluna_conteudo.conteudo != "" ? (
                      <span className="fw-bold text-white">
                        {coluna.coluna_conteudo.conteudo}
                      </span>
                    ) : (
                      <span style={{ color: "transparent" }}> Default </span>
                    )}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem header>
                      <span>Etiquetas</span>
                    </DropdownItem>
                    {etiquetas.map((etiqueta) => (
                      <React.Fragment key={etiqueta.id}>
                        <DropdownItem
                          onClick={() =>
                            handleConteudo(coluna.coluna_id, etiqueta.id)
                          }
                        >
                          <div
                            className="item-dropdown-etiqueta text-center"
                            style={{ backgroundColor: etiqueta.cor_hexa }}
                          >
                            {etiqueta.conteudo == "" ? (
                              <span style={{ color: "transparent" }}>
                                {" "}
                                Default{" "}
                              </span>
                            ) : (
                              <>{etiqueta.conteudo}</>
                            )}
                          </div>
                        </DropdownItem>
                      </React.Fragment>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </TableCell>
            ) : null}

            {coluna.coluna_tipo === "Data" ? (
              <TableCell align="center">
                <input
                  className="input-sem-borda form-control text-center"
                  type="date"
                  value={coluna.coluna_conteudo}
                  onChange={(event) => {
                    handleConteudo(coluna.coluna_id, event.target.value);
                  }}
                />
              </TableCell>
            ) : null}
            {coluna.coluna_tipo === "Números" ? (
              <TableCell align="center">
                <CelulaNumeros
                  key={elemento.elemento_id}
                  colunaId={coluna.coluna_id}
                  conteudo={coluna.coluna_conteudo}
                  handleConteudo={handleConteudo}
                  elementoControle={elementoControle}
                  setElementoControle={setElementoControle}
                />
              </TableCell>
            ) : null}
          </React.Fragment>
        ))}
      </TableRow>
      {/* Subelementos */}
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={elemento.colunas.length + 1}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className="mt-3 mb-2"
          >
            <Subelementos
              subelementos={elemento.subelementos}
              quadroId={quadroId}
              colunasSubelemento={colunasSubelemento}
              elementoId={elemento.elemento_id}
              elementoControle={elementoControle}
              setElementoControle={setElementoControle}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
