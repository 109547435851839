import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";

export default function CelulaNumeros(props) {
  const { colunaId, conteudo, handleConteudo } = props;

  return (
    <React.Fragment>
      <Input
        type="number"
        className="input-sem-bordas text-center font-size-14"
        defaultValue={conteudo}
        onBlur={(event) => {
          let conteudos = event.target.value;
          props.handleConteudo(colunaId, conteudos);
        }}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            let conteudos = event.target.value;
            props.handleConteudo(colunaId, conteudos);
          }
        }}
      />
    </React.Fragment>
  );
}
