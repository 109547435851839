import React, { useEffect } from "react";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Placeholder,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import NoProfileImg from "./../../../assets/images/no-photo-profile.png";
import {
  Checkbox,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import CelulaNumeros from "./CelulaNumeros";
import api from "../../../services/api";

export default function Subelementos(props) {
  const {
    subelementos,
    quadroId,
    colunasSubelemento,
    elementoId,
    elementoControle,
    setElementoControle,
  } = props;

  const [pessoas, setPessoas] = React.useState([]);
  const [etiquetas, setEtiquetas] = React.useState([]);
  const [colunaTipos, setColunaTipos] = React.useState([]);

  useEffect(() => {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .get(`/coluna-tipo`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setColunaTipos(response.data.tipos);
      })
      .catch((err) => {});
  }, []);

  function criarColuna(tipoId) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .post(
        `/coluna-subelemento`,
        {
          quadroId,
          colunaTipoId: tipoId,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setElementoControle(!elementoControle);
      })
      .catch((err) => {});
  }

  function handleRenomearColuna(event, colunaId, defaultValue) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    if (event.target.value !== "") {
      api.post(
        `/coluna-subelemento/` + colunaId,
        {
          colunaNome: event.target.value,
        },
        {
          headers: { Authorization: token },
        }
      );
    } else {
      event.target.value = defaultValue;
    }
  }

  function handleExcluirColuna(colunaId) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .delete(`/coluna-subelemento/` + colunaId, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setElementoControle(!elementoControle);
      })
      .catch((err) => {});
  }

  function handleCriarSubelemento(event) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    if (event.target.value !== "") {
      api
        .post(
          `/subelemento`,
          {
            subelementoTitulo: event.target.value,
            elementoId: elementoId,
            quadroId: quadroId,
          },
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setElementoControle(!elementoControle);
        })
        .catch((err) => {
          //toast.error(err.response.data.message);
        });
      event.target.value = "";
    }
  }

  function handleRenomearSubelemento(event, subelementoId, defaultValue) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    if (event.target.value !== "") {
      api
        .post(
          `/subelemento/` + subelementoId,
          {
            subelementoTitulo: event.target.value,
          },
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setElementoControle(!elementoControle);
        });
    } else {
      event.target.value = defaultValue;
    }
  }

  function handleExcluirSubelemento(subelementoId) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .delete(`/subelemento/` + subelementoId, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setElementoControle(!elementoControle);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  function handlePessoas(arrayPessoas) {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    if (arrayPessoas === "") {
      arrayPessoas = [];
    }
    api
      .post(
        `/pessoas`,
        {
          quadroId,
          arrayPessoas,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setPessoas(response.data.pessoas);
      })
      .catch((e) => {});
  }

  function handleConteudo(subelementoId, colunaId, conteudo) {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .post(
        `/conteudo-subelemento`,
        {
          subelementoId: subelementoId,
          quadroColunaSubelementoId: colunaId,
          conteudo: conteudo,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setElementoControle(!elementoControle);
      })
      .catch((e) => {});
  }

  function handleEtiquetas(colunaId) {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .get(`/etiqueta/coluna-subelemento/` + colunaId, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setEtiquetas(response.data.etiquetas);
      })
      .catch((e) => {});
  }

  return (
    <Table className="table table-hover table-bordered mb-3" size="small">
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              borderLeft: "hidden",
              borderBottom: "hidden",
              borderTop: "hidden",
            }}
          ></TableCell>
          {/* selecionar todos os subelementos */}
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              inputProps={{
                "aria-label": "selecionar todos",
              }}
            />{" "}
          </TableCell>
          {colunasSubelemento.map((coluna) => (
            <TableCell
              key={coluna.coluna_id}
              align="center"
              className="cell-coluna-nome"
            >
              {coluna.coluna_tipo !== "Subelemento" ? (
                <>
                  <div className="d-flex align-items-center justify-content-center">
                    <Input
                      className="text-dark input-sem-borda font-size-14 text-center"
                      defaultValue={coluna.coluna_nome}
                      maxLength="99"
                      onBlur={(event) => {
                        handleRenomearColuna(
                          event,
                          coluna.coluna_id,
                          coluna.coluna_nome
                        );
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleRenomearColuna(
                            event,
                            coluna.coluna_id,
                            coluna.coluna_nome
                          );
                        }
                      }}
                    />
                    <UncontrolledDropdown>
                      <DropdownToggle
                        size="sm"
                        className="button-coluna-opcoes font-size-16"
                      >
                        <i className="bx bx-dots-horizontal" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => handleExcluirColuna(coluna.coluna_id)}
                        >
                          <i className="bx bx-trash me-1" />
                          <span>Excluir Coluna</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </>
              ) : (
                <span>{coluna.coluna_nome}</span>
              )}
            </TableCell>
          ))}
          <TableCell align="center">
            <UncontrolledDropdown>
              <DropdownToggle size="sm" className="button-nova-coluna">
                <i className="bx bx-plus font-size-14 text-dark" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>
                  <span>Essenciais</span>
                </DropdownItem>
                {colunaTipos ? (
                  colunaTipos.map((tipo) => (
                    <DropdownItem
                      key={tipo.id}
                      onClick={() => criarColuna(tipo.id)}
                    >
                      {tipo.nome !== "Elemento" &&
                      tipo.nome !== "Subelemento" ? (
                        <>{tipo.nome}</>
                      ) : null}
                    </DropdownItem>
                  ))
                ) : (
                  <Placeholder color="light" />
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </TableCell>
        </TableRow>
      </TableHead>
      {/* COLOCAR SUBELEMENTOS AKI */}
      <TableBody>
        {subelementos.map((subelemento) => (
          <React.Fragment key={subelemento.subelemento_id}>
            <TableRow hover className="row-elementos">
              {/* opções de elemento */}
              <TableCell
                sx={{
                  borderLeft: "hidden",
                  borderBottom: "hidden",
                  borderTop: "hidden",
                }}
                align="center"
              >
                <UncontrolledDropdown>
                  <DropdownToggle className="button-elemento-opcoes">
                    <i className=" d-flex bx bx-dots-horizontal font-size-14 " />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        handleExcluirSubelemento(subelemento.subelemento_id);
                      }}
                    >
                      <i className="bx bx-trash me-1" />
                      <span>Excluir Subelemento</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </TableCell>
              {/* elemento.elemento_id */}
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  inputProps={{
                    "aria-label": "selecionar todos",
                  }}
                />
              </TableCell>

              {/* elemento.colunasElemento */}
              {subelemento.Subelemento ? (
                <TableCell align="left" className="d-flex align-items-center">
                  <Input
                    className="input-sem-borda font-size-14"
                    defaultValue={subelemento.Subelemento}
                    onBlur={(event) => {
                      handleRenomearSubelemento(
                        event,
                        subelemento.subelemento_id,
                        subelemento.Subelemento
                      );
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleRenomearSubelemento(
                          event,
                          subelemento.subelemento_id,
                          subelemento.Subelemento
                        );
                      }
                    }}
                  />
                </TableCell>
              ) : null}

              {/* Pessoa */}
              {subelemento.colunas.map((coluna) => (
                <React.Fragment key={coluna.coluna_id}>
                  {/* Pessoa */}
                  {coluna.coluna_tipo === "Pessoa" ? (
                    <TableCell align="center" className="cell-elementos-pessoa">
                      <Row className="d-flex align-items-center">
                        <Col lg="auto" className="">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              size="sm"
                              className="button-adicionar-pessoa btn btn-rounded p-0"
                              onClick={() =>
                                handlePessoas(coluna.coluna_conteudo)
                              }
                            >
                              <i className="bx bx-plus font-size-16 m-0" />
                            </DropdownToggle>
                            <DropdownMenu>
                              {coluna.coluna_conteudo.length > 0 ? (
                                <>
                                  <DropdownItem header>
                                    <span>Responsaveis</span>
                                  </DropdownItem>
                                  {coluna.coluna_conteudo.map(
                                    (responsavel, index) => (
                                      <React.Fragment
                                        key={responsavel.usuario_id}
                                      >
                                        <DropdownItem text>
                                          <Row className="d-flex align-items-center justify-content-center gap-2">
                                            <Col>
                                              <div>
                                                {responsavel.nome}{" "}
                                                {responsavel.sobrenome}
                                              </div>
                                            </Col>
                                            <Col>
                                              <Button
                                                size="sm"
                                                className="button-excluir-pessoa btn btn-square font-size-14 text-dark"
                                                onClick={() => {
                                                  let conteudo =
                                                    coluna.coluna_conteudo;
                                                  if (conteudo.length > 0) {
                                                    conteudo = conteudo.map(
                                                      (usuario) =>
                                                        usuario.usuario_id
                                                    );
                                                  } else {
                                                    conteudo = [];
                                                  }
                                                  conteudo.splice(index, 1);
                                                  handleConteudo(
                                                    subelemento.subelemento_id,
                                                    coluna.coluna_id,
                                                    conteudo
                                                  );
                                                  pessoas.push(responsavel);
                                                }}
                                              >
                                                <i className="bx bx-x font-size-16 m-0" />
                                              </Button>
                                            </Col>
                                          </Row>
                                        </DropdownItem>
                                      </React.Fragment>
                                    )
                                  )}
                                </>
                              ) : null}
                              <DropdownItem header>
                                <span>Pessoas sugeridas</span>
                              </DropdownItem>
                              {pessoas.map((pessoa) => (
                                <React.Fragment key={pessoa.usuario_id}>
                                  <DropdownItem
                                    onClick={() => {
                                      let conteudo = coluna.coluna_conteudo;
                                      if (conteudo.length > 0) {
                                        conteudo = conteudo.map(
                                          (usuario) => usuario.usuario_id
                                        );
                                      } else {
                                        conteudo = [];
                                      }
                                      conteudo.push(pessoa.usuario_id);
                                      handleConteudo(
                                        subelemento.subelemento_id,
                                        coluna.coluna_id,
                                        conteudo
                                      );
                                    }}
                                  >
                                    <span>
                                      {pessoa.nome} {pessoa.sobrenome}
                                    </span>
                                  </DropdownItem>
                                </React.Fragment>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Col>

                        <Col className="d-flex justify-content-center pe-5">
                          {coluna.coluna_conteudo.length > 0 ? (
                            <div className="avatar-group d-inline-flex">
                              {coluna.coluna_conteudo.map((conteudo, index) => (
                                <React.Fragment key={index}>
                                  <>
                                    {index < 2 ? (
                                      <Link
                                        to="#"
                                        className="  avatar-group-item"
                                        key={index}
                                      >
                                        {" "}
                                        <div className="avatar-xs">
                                          <span
                                            className={
                                              "avatar-title rounded-circle text-white font-size-16"
                                            }
                                          >
                                            {conteudo.nome
                                              .substring(0, 1)
                                              .toUpperCase() +
                                              conteudo.sobrenome
                                                .substring(0, 1)
                                                .toUpperCase()}
                                          </span>
                                        </div>
                                      </Link>
                                    ) : null}
                                  </>
                                </React.Fragment>
                              ))}
                              {coluna.coluna_conteudo.length >= 3 ? (
                                <Link to="#" className="  avatar-group-item">
                                  {" "}
                                  <div className="avatar-xs">
                                    <span
                                      className={
                                        "avatar-title rounded-circle text-white font-size-16"
                                      }
                                    >
                                      <div>
                                        <span>
                                          +{coluna.coluna_conteudo.length - 2}
                                        </span>
                                      </div>
                                    </span>
                                  </div>
                                </Link>
                              ) : null}
                            </div>
                          ) : (
                            <div className="avatar-group d-inline-flex">
                              <Link to="#" className="  avatar-group-item">
                                {" "}
                                <div className="avatar-xs">
                                  <span
                                    className={
                                      "avatar-title rounded-circle text-white font-size-16"
                                    }
                                  >
                                    <img
                                      src={NoProfileImg}
                                      className="rounded-circle avatar-xs"
                                    />
                                  </span>
                                </div>
                              </Link>
                            </div>
                          )}
                        </Col>
                        {/* */}
                      </Row>
                    </TableCell>
                  ) : null}

                  {coluna.coluna_tipo === "Status" ? (
                    <TableCell align="center">
                      <UncontrolledDropdown className="cell-status">
                        <DropdownToggle
                          style={{
                            backgroundColor: coluna.coluna_conteudo.cor_hexa,
                          }}
                          className="button-coluna-status"
                          data-bs-toggle="dropdown"
                          onClick={() => handleEtiquetas(coluna.coluna_id)}
                        >
                          {coluna.coluna_conteudo.conteudo != "" ? (
                            <span className="fw-bold text-white">
                              {coluna.coluna_conteudo.conteudo}
                            </span>
                          ) : (
                            <span style={{ color: "transparent" }}>
                              {" "}
                              Default{" "}
                            </span>
                          )}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem header>
                            <span>Etiquetas</span>
                          </DropdownItem>
                          {etiquetas.map((etiqueta) => (
                            <React.Fragment key={etiqueta.id}>
                              <DropdownItem
                                onClick={() =>
                                  handleConteudo(
                                    subelemento.subelemento_id,
                                    coluna.coluna_id,
                                    etiqueta.id
                                  )
                                }
                              >
                                <div
                                  className="item-dropdown-etiqueta text-center"
                                  style={{ backgroundColor: etiqueta.cor_hexa }}
                                >
                                  {etiqueta.conteudo == "" ? (
                                    <span style={{ color: "transparent" }}>
                                      {" "}
                                      Default{" "}
                                    </span>
                                  ) : (
                                    <>{etiqueta.conteudo}</>
                                  )}
                                </div>
                              </DropdownItem>
                            </React.Fragment>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </TableCell>
                  ) : null}

                  {coluna.coluna_tipo === "Data" ? (
                    <TableCell align="center">
                      <input
                        className="input-sem-borda form-control text-center"
                        type="date"
                        value={coluna.coluna_conteudo}
                        onChange={(event) => {
                          handleConteudo(
                            subelemento.subelemento_id,
                            coluna.coluna_id,
                            event.target.value
                          );
                        }}
                      />
                    </TableCell>
                  ) : null}
                  {coluna.coluna_tipo === "Números" ? (
                    <TableCell align="center">
                      <CelulaNumeros
                        key={subelemento.subelemento_id}
                        colunaId={coluna.coluna_id}
                        conteudo={coluna.coluna_conteudo}
                        handleConteudo={handleConteudo}
                        elementoControle={elementoControle}
                        setElementoControle={setElementoControle}
                      />
                    </TableCell>
                  ) : null}
                </React.Fragment>
              ))}
            </TableRow>
          </React.Fragment>
        ))}
        <TableRow hover>
          <TableCell
            sx={{
              borderLeft: "hidden",
              borderBottom: "hidden",
              borderTop: "hidden",
            }}
          ></TableCell>
          <TableCell padding="checkbox">
            <Checkbox color="primary" disabled />
          </TableCell>
          <TableCell
            colSpan={colunasSubelemento.length + 1}
            sx={{ borderRight: "none" }}
            align="left"
          >
            <Input
              className="input-novo-elemento font-size-14"
              placeholder="+ Adicionar Subelemento"
              maxLength="99"
              onBlur={handleCriarSubelemento}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleCriarSubelemento(event);
                }
              }}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
