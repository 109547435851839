import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import api from "../../../services/api";
import NovoWorkspaceModal from "../Modais/NovoWorkspaceModal";
import NovoQuadroModal from "../Modais/NovoQuadroModal";
import {
  Button,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import ExcluirWorkspaceModal from "../Modais/ExcluirWorkspaceModal";

function MenuPrincipal() {
  let params = useParams();

  const [workspace, setWorkspace] = useState({});

  const [workspaces, setWorkspaces] = useState([[]]);
  const [abreNovoWorkspaceModal, setAbreNovoWorkspaceModal] = useState(false);
  const [abreNovoQuadroModal, setAbreNovoQuadroModal] = useState(false);
  const [excluirWorkspaceModal, setExcluirWorkspaceModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .get(`/workspaces/select-workspaces`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setWorkspaces(response.data.workspaces);
      })
      .catch((err) => {});

    api
      .get(`/workspaces/${params.workspaceId}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setWorkspace(response.data.workspace);
      })
      .catch((err) => {});
  }, [params.workspaceId]);

  function toggleAbreNovoWorkspaceModal() {
    setAbreNovoWorkspaceModal(!abreNovoWorkspaceModal);
  }

  function toggleAbreNovoQuadroModal() {
    setAbreNovoQuadroModal(!abreNovoQuadroModal);
  }

  function toggleAbreExcluirWorkspaceModal() {
    setExcluirWorkspaceModal(!excluirWorkspaceModal);
  }

  return (
    <CardBody className="border-bottom mb-0">
      <div className="d-flex align-items-center justify-content-between gap-2">
        <UncontrolledDropdown className="dropdown d-inline-block ">
          <DropdownToggle type="menu" className="btn btn-light">
            <div className="d-flex align-items-center gap-2 ">
              <div
                role="img"
                className="workspace-icone-mini text-white font-size-14"
                style={{ backgroundColor: workspace.cor }}
              >
                {workspace.inicial}
              </div>
              <span className="font-size-14 text-truncate">
                {workspace.nome} <i className="mdi mdi-chevron-down" />
              </span>
            </div>
          </DropdownToggle>
          <DropdownMenu>
            {workspaces.map((item) => (
              <li key={"item-" + item.id}>
                <DropdownItem href={item.id}>
                  <div className="d-flex align-items-center gap-2 ">
                    <div
                      role="img"
                      className="workspace-icone-mini text-white font-size-12"
                      style={{ backgroundColor: item.cor }}
                    >
                      {item.inicial}
                    </div>
                    <span className="font-size-12 ">{item.nome} </span>
                  </div>
                </DropdownItem>
              </li>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <div className="flex-shrink-0">
          <Button
            className=" me-1"
            color="primary"
            onClick={(event) => toggleAbreNovoQuadroModal()}
          >
            + Adicionar Quadro
          </Button>
          <NovoWorkspaceModal
            isOpen={abreNovoWorkspaceModal}
            toggle={toggleAbreNovoWorkspaceModal}
          />
          <UncontrolledDropdown className="dropdown d-inline-block me-1">
            <DropdownToggle
              type="menu"
              className="btn btn-light"
              id="dropdownMenuButton1"
            >
              <i className="mdi mdi-dots-vertical"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={toggleAbreNovoWorkspaceModal}>
                <i className="bx bx-plus me-1" />{" "}
                <span>Nova área de trabalho</span>
              </DropdownItem>
              <DropdownItem onClick={toggleAbreExcluirWorkspaceModal}>
                <i className="bx bx-trash me-1" />{" "}
                <span>Excluir área de trabalho</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NovoQuadroModal
            isOpen={abreNovoQuadroModal}
            toggle={toggleAbreNovoQuadroModal}
          />
          <ExcluirWorkspaceModal
            isOpen={excluirWorkspaceModal}
            toggle={toggleAbreExcluirWorkspaceModal}
          />
        </div>
      </div>
    </CardBody>
  );
}

export default MenuPrincipal;
