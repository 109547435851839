import React, { useState, useEffect } from "react";
import classnames from "classnames";
import AdicionaViewDropdown from "./AdicionaViewDropdown";
import api from "../../services/api";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";

import Tabela from "./Tabela/index";
import { Link } from "react-router-dom";

export default function ViewsQuadro(props) {
  const {
    quadroId,
    views,
    colunasSubelemento,
    colunasElemento,
    grupos,
    grupoControle,
    setGrupoControle,
    gruposLength,
    setGruposLength,
    elementoControle,
    setElementoControle,
  } = props;

  //nav
  const [activeTab, setactiveTab] = useState(0);
  const [tipos, setTipos] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .get(`/view-tipo`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setTipos(response.data.tipos);
      })
      .catch((err) => {});
  }, []);

  function criaGrupo() {
    const token = localStorage.getItem("tokenAS3AgileBoard");

    api
      .post(`/grupos`, { quadroId }, { headers: { Authorization: token } })
      .then((response) => {
        setGrupoControle(!grupoControle);
      });
  }

  return (
    <>
      {/* Nav Views */}
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Nav tabs className="rounded mb-3" role="tablist">
                {views.map((view, index) => (
                  <NavItem key={index}>
                    <NavLink
                      id={"quadro-nav-link-views-" + view.view_id}
                      className={classnames("text-dark", {
                        active: activeTab === index,
                      })}
                      onClick={() => {
                        setactiveTab(index);
                      }}
                    >
                      <div className="d-inline-flex align-items-center justify-content-center gap-1">
                        {view.view_is_default ? (
                          <i className="bx bx-home" />
                        ) : null}
                        <span className="me-1">{view.view_nome}</span>
                        <UncontrolledDropdown>
                          <DropdownToggle className="view-options-button mt-1">
                            <i className="bx bx-dots-horizontal" />
                          </DropdownToggle>
                          <DropdownMenu
                            style={{
                              margin: 0,
                              zIndex: 999,
                              overflow: "visible",
                            }}
                          >
                            <DropdownItem>
                              <i className="bx bx-trash me-1" />
                              <span>Excluir View</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </NavLink>
                  </NavItem>
                ))}

                <AdicionaViewDropdown tipos={tipos} setTipos={setTipos} />
              </Nav>
            </Col>
          </Row>

          <Row className="d-inline-flex justify-content-start align-items-center g-3 mb-3">
            <Col>
              <Button
                color="primary"
                className="btn text-truncate"
                onClick={() => {
                  criaGrupo();
                }}
              >
                Criar Grupo de Elementos
              </Button>
            </Col>
          </Row>

          {/* conteudo das Views */}
          <TabContent activeTab={activeTab}>
            {views.map((view, index) => (
              <React.Fragment key={index}>
                {/* VIEW TIPO TABELA */}
                {view.view_tipo === "Tabela" ? (
                  <TabPane tabId={index}>
                    <Tabela
                      quadroId={quadroId}
                      grupos={grupos}
                      colunasElemento={colunasElemento}
                      colunasSubelemento={colunasSubelemento}
                      gruposLength={gruposLength}
                      setGruposLength={setGruposLength}
                      grupoControle={grupoControle}
                      setGrupoControle={setGrupoControle}
                      elementoControle={elementoControle}
                      setElementoControle={setElementoControle}
                    />
                  </TabPane>
                ) : null}
              </React.Fragment>
            ))}
          </TabContent>
        </Col>
      </Row>
    </>
  );
}
