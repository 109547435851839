import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import avatar from "../../assets/images/no-photo-profile.png";
import Layout from "../../components/VerticalLayout";
import api from "../../services/api";

const UserProfile = (props) => {
  const [emailUsuario, setEmailUsuario] = useState(
    localStorage.getItem("userEmailAS3AgileBoard")
  );

  //Modal alterar senha
  const [abrirModal, setAbrirModal] = useState(false);

  function toggleModal() {
    setAbrirModal(!abrirModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  //Form alterar senha
  const [alterarSenha, setAlterarSenha] = useState({
    senha_atual: null,
    nova_senha: null,
    repetir_senha: null,
  });
  const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
  const [desabilitaBotao, setDesabilitaBotao] = useState(false);

  function onChange(event) {
    const { value, name } = event.target;

    setAlterarSenha({
      ...alterarSenha,
      [name]: value,
    });
  }

  function validateForm() {
    return (
      regex.test(alterarSenha.nova_senha) &&
      regex.test(alterarSenha.repetir_senha) &&
      alterarSenha.nova_senha === alterarSenha.repetir_senha
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setDesabilitaBotao(true);

    const token = localStorage.getItem("token");
    try {
      api
        .post(`/gerenciamento/conta/alterar-senha`, alterarSenha, {
          headers: { Authorization: token },
        })
        .then((response) => {})
        .catch((err) => {});
    } catch (e) {}
  }

  document.title = "Perfil | AS3 Agile Board - AS3 Engenharia Elétrica RJ";

  return (
    <Layout>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card style={{ minHeight: "355px" }}>
                <CardBody className="profile-container">
                  <Row>
                    <Row className="mb-3">
                      <Col className="d-flex justify-content-center">
                        <div>
                          <img
                            src={avatar}
                            alt=""
                            className="avatar-xl rounded-circle img-thumbnail"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        {emailUsuario ? (
                          <p className="font-size-24 text-white">
                            {emailUsuario}
                          </p>
                        ) : (
                          <div>
                            <p className="placeholder-glow font-size-24">
                              <span className="placeholder col-12"></span>
                            </p>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Row>
                  {/* <Media body className="align-self-center">
                      <Col lg="6" xl="6" className=" mb-0">
                        <dl className="row mb-0">
                          <dt className="col-sm-3">Nome:</dt>
                          <dd className="col-sm-9"> {usuario.usuario_nome}</dd>
                          <dt className="col-sm-3">E-mail:</dt>
                          <dd className="col-sm-9">{usuario.usuario_email}</dd>
                          <dt className="col-sm-3">Organização:</dt>
                          <dd className="col-sm-9">
                            {usuario.organizacao_nome}
                          </dd>

                          <dt className="col-sm-3">Senha:</dt>
                          <dd className="col-sm-9">
                            <Button
                              className=" btn btn-sm"
                              color="primary"
                              data-dismiss="modal"
                              onClick={() => {
                                toggleModal();
                              }}
                            >
                              Alterar
                            </Button>
                            <Modal
                              centered
                              isOpen={abrirModal}
                              toggle={() => {
                                toggleModal();
                              }}
                            >
                              <ModalHeader>Alterar senha</ModalHeader>
                              <ModalBody>
                                <Form
                                  className="form-horizontal"
                                  onSubmit={handleSubmit}
                                >
                                  <FormGroup>
                                    <Label className=" text-dark">
                                      Informe a senha atual:
                                    </Label>
                                    <Input
                                      name="senha_atual"
                                      placeholder="Senha atual"
                                      type="password"
                                      className="form-control"
                                      onChange={onChange}
                                      required
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className=" text-dark">
                                      Informe a nova senha:
                                    </Label>
                                    <Input
                                      name="nova_senha"
                                      placeholder="Nova senha"
                                      type="password"
                                      className="form-control"
                                      onChange={onChange}
                                      required
                                    />
                                    {regex.test(
                                      alterarSenha.nova_senha
                                    ) ? null : (
                                      <span className="text-danger">
                                        {" "}
                                        A senha precisa ter pelo menos 8
                                        caracteres, uma letra maiúscula, uma
                                        letra minúscula e um caractere especial
                                      </span>
                                    )}
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className=" text-dark">
                                      Repita a nova senha:
                                    </Label>
                                    <Input
                                      name="repetir_senha"
                                      placeholder="Confirmar nova senha"
                                      type="password"
                                      className="form-control"
                                      onChange={onChange}
                                      required
                                    />
                                    {alterarSenha.nova_senha ===
                                    alterarSenha.repetir_senha ? null : (
                                      <span className="text-danger">
                                        {" "}
                                        As senhas não conferem. Verifique se
                                        houve um erro de digitação.
                                      </span>
                                    )}
                                  </FormGroup>
                                  <Button
                                    color="link"
                                    type="reset"
                                    onClick={() => {
                                      toggleModal();
                                    }}
                                  >
                                    Cancelar
                                  </Button>{" "}
                                  <Button
                                    disabled={
                                      !validateForm() || desabilitaBotao
                                    }
                                    type="submit"
                                    color="primary"
                                  >
                                    Alterar
                                  </Button>
                                </Form>
                              </ModalBody>
                              <ModalFooter></ModalFooter>
                            </Modal>
                          </dd>
                        </dl>
                      </Col> 
                    </Media>*/}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default UserProfile;
