import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../components/VerticalLayout";
import api from "../../services/api";
import "./styles.css";

import MenuPrincipal from "./../../components/Common/MenuPrincipal/index.js";

function Workspace() {
  let params = useParams();

  const [workspace, setWorkspace] = useState({});
  const [quadros, setQuadros] = useState([]);

  document.title =
    "Área de trabalho | AS3 Agile Board - AS3 Engenharia Elétrica RJ";

  useEffect(() => {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .get(`/workspaces/${params.workspaceId}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setWorkspace(response.data.workspace);
        setQuadros(response.data.quadros);
      })
      .catch((err) => {});
  }, [params.workspaceId]);

  return (
    <React.Fragment>
      <ToastContainer />
      <Layout>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col lg="12">
                <Card>
                  <MenuPrincipal />
                  <CardBody>
                    <Row className="d-flex align-items-center mb-5">
                      <Col lg="6">
                        <div className="d-flex align-items-center gap-3">
                          <div
                            role="img"
                            className="workspace-icone text-white"
                            style={{ backgroundColor: workspace.cor }}
                          >
                            {workspace.inicial}
                          </div>
                          <h4>{workspace.nome}</h4>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6" xl="6">
                        <Table hover responsive>
                          <tbody>
                            {quadros.map((quadro) => (
                              <tr key={quadro.id}>
                                <td>
                                  <Link
                                    to={"quadros/" + quadro.id}
                                    className="text-dark"
                                  >
                                    <Row className="d-flex align-items-center">
                                      <Col lg="6">
                                        <div className="d-flex align-items-center gap-3 font-size-16">
                                          <i className="bx bx-dock-left" />
                                          <span>{quadro.nome}</span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
}

export default Workspace;
