import React, { useEffect } from "react";
import AutosizeInput from "react-input-autosize";
import {
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
} from "@mui/material";
import Elementos from "./Elementos";
import { Link } from "react-router-dom";
import { Table, Input, Row } from "reactstrap";
import api from "../../../services/api";
import { useState } from "react";
import Dropdown from "react-multilevel-dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Placeholder,
} from "reactstrap";

export default function Grupos(props) {
  const {
    quadroId,
    grupo,
    colunasElemento,
    colunasSubelemento,
    gruposLength,
    setGruposLength,
    elementoControle,
    setElementoControle,
    grupoControle,
    setGrupoControle,
  } = props;

  const [open, setOpen] = React.useState(grupo.grupo_is_open);
  const [arrayCores, setArrayCores] = useState(null);
  const [grupoCorHexa, setGrupoCorHexa] = useState(grupo.grupo_cor_hexa);
  const [colunaTipos, setColunaTipos] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .get(`/coluna-tipo`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setColunaTipos(response.data.tipos);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api.post(
      `/grupos/` + grupo.grupo_id + `/is-open`,
      { open },
      { headers: { Authorization: token } }
    );
    api
      .get("/cor-hexa", { headers: { Authorization: token } })
      .then((response) => {
        setArrayCores(response.data.arrayCores);
      });
  }, [open]);

  function handleCorGrupo(corHexaId) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api.post(
      `/grupos/` + grupo.grupo_id + `/cor`,
      { corHexaId },
      { headers: { Authorization: token } }
    );
  }

  function handleExcluirGrupo() {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .delete(`/grupos/` + grupo.grupo_id, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setGruposLength(response.data.grupos.length);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  function handleRenomearGrupo(event, grupoId, defaultValue) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    if (event.target.value !== "") {
      api.post(
        `/grupos/` + grupoId + "/nome",
        {
          novoNomeGrupo: event.target.value,
        },
        {
          headers: { Authorization: token },
        }
      );
    } else {
      event.target.value = defaultValue;
    }
  }

  function handleRenomearColuna(event, colunaId, defaultValue) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    if (event.target.value !== "") {
      api.post(
        `/coluna-elemento/` + colunaId,
        {
          colunaNome: event.target.value,
        },
        {
          headers: { Authorization: token },
        }
      );
    } else {
      event.target.value = defaultValue;
    }
  }

  function handleCriarElemento(event) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    if (event.target.value !== "") {
      api
        .post(
          `/elemento`,
          {
            elementoTitulo: event.target.value,
            grupoId: grupo.grupo_id,
            quadroId: grupo.grupo_quadro_id,
          },
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setElementoControle(!elementoControle);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
      event.target.value = "";
    }
  }

  function criarColunaElemento(tipoId) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .post(
        `/coluna-elemento`,
        {
          quadroId,
          colunaTipoId: tipoId,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setGrupoControle(!grupoControle);
      })
      .catch((err) => {});
  }

  function handleExcluirColuna(colunaId) {
    const token = localStorage.getItem("tokenAS3AgileBoard");
    api
      .delete(`/coluna-elemento/` + colunaId, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setGrupoControle(!grupoControle);
      })
      .catch((err) => {});
  }

  return (
    <React.Fragment>
      {/* Toasts */}
      <ToastContainer />
      <Row className="d-flex g-2 align-items-center float-left mb-1">
        <div className="col-auto">
          <Dropdown
            buttonClassName="button-grupo-opcoes"
            wrapperClassName="wrapper-grupo-opcoes"
            title={
              <>
                <i className=" d-flex bx bx-dots-horizontal font-size-14" />
              </>
            }
            position="right"
          >
            <Dropdown.Item>
              <i
                className="bx bxs-circle me-1"
                style={{ color: grupoCorHexa }}
              />
              <span>Alterar Cor do Grupo</span>
              <i className="bx bx-chevron-right ms-1" />
              <Dropdown.Submenu position="right">
                <div className="grupo-color-items-wrapper">
                  {arrayCores ? (
                    <>
                      {arrayCores.map((cor) => (
                        <Dropdown.Item
                          key={cor.id}
                          onClick={() => {
                            setGrupoCorHexa(cor.cod);
                            handleCorGrupo(cor.id);
                          }}
                        >
                          <div
                            style={{ backgroundColor: cor.cod }}
                            className="color-item"
                          ></div>
                        </Dropdown.Item>
                      ))}
                    </>
                  ) : null}
                </div>
              </Dropdown.Submenu>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleExcluirGrupo();
              }}
            >
              <i className="bx bx-trash me-1" />
              <span>Excluir Grupo</span>
            </Dropdown.Item>
          </Dropdown>
        </div>
        <div className="col-auto">
          {/* expandir/recolher grupo */}
          <Link
            to="#"
            className="toggle-subelemento-button"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? (
              <i className=" d-flex bx bx-chevron-down font-size-22" />
            ) : (
              <i className=" d-flex bx bx-chevron-right font-size-22" />
            )}
          </Link>
        </div>
        <div className="col-auto">
          {/* Nome do grupo */}
          <AutosizeInput
            className=" input-sem-borda"
            maxLength={29}
            defaultValue={grupo.grupo_nome}
            onBlur={(event) => {
              handleRenomearGrupo(event, grupo.grupo_id, grupo.grupo_nome);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleRenomearGrupo(event, grupo.grupo_id, grupo.grupo_nome);
              }
            }}
            inputStyle={{
              border: "1px solid #FFFFFF",
              borderRadius: 3,
              fontSize: "18px",
              fontWeight: 600,
              color: grupoCorHexa,
            }}
          />
        </div>
        <div className="col-auto">
          <span>{grupo.elementos.length + " elementos"}</span>
        </div>
      </Row>
      <Row>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <TableContainer>
            <Table
              className="table table-hover table-bordered mb-3"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {/* elemento.elemento_id */}
                  <TableCell
                    sx={{
                      borderLeft: "hidden",
                      borderBottom: "hidden",
                      borderTop: "hidden",
                    }}
                  ></TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      inputProps={{
                        "aria-label": "selecionar todos",
                      }}
                    />
                  </TableCell>
                  {colunasElemento.map((coluna) => (
                    <TableCell
                      align="center"
                      key={coluna.coluna_id}
                      className="cell-coluna-nome"
                    >
                      {coluna.coluna_tipo !== "Elemento" ? (
                        <>
                          <div className="d-flex align-items-center justify-content-center">
                            <Input
                              className="text-dark input-sem-borda font-size-14 text-center"
                              defaultValue={coluna.coluna_nome}
                              onBlur={(event) => {
                                handleRenomearColuna(
                                  event,
                                  coluna.coluna_id,
                                  coluna.coluna_nome
                                );
                              }}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  handleRenomearColuna(
                                    event,
                                    coluna.coluna_id,
                                    coluna.coluna_nome
                                  );
                                }
                              }}
                            />
                            <UncontrolledDropdown>
                              <DropdownToggle
                                size="sm"
                                className="button-coluna-opcoes font-size-16"
                              >
                                <i className="bx bx-dots-horizontal" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() =>
                                    handleExcluirColuna(coluna.coluna_id)
                                  }
                                >
                                  <i className="bx bx-trash me-1" />
                                  <span>Excluir Coluna</span>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </>
                      ) : (
                        <span>{coluna.coluna_nome}</span>
                      )}
                    </TableCell>
                  ))}
                  <TableCell align="center">
                    <UncontrolledDropdown>
                      <DropdownToggle size="sm" className="button-nova-coluna">
                        <i className="bx bx-plus font-size-14 text-dark" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>
                          <span>Essenciais</span>
                        </DropdownItem>
                        {colunaTipos ? (
                          colunaTipos.map((tipo) => (
                            <DropdownItem
                              key={tipo.id}
                              onClick={() => criarColunaElemento(tipo.id)}
                            >
                              {tipo.nome !== "Elemento" &&
                              tipo.nome !== "Subelemento" ? (
                                <>{tipo.nome}</>
                              ) : null}
                            </DropdownItem>
                          ))
                        ) : (
                          <Placeholder color="light" />
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {grupo.elementos.map((elemento) => (
                  <Elementos
                    key={elemento.elemento_id}
                    elemento={elemento}
                    quadroId={quadroId}
                    colunasElemento={colunasElemento}
                    colunasSubelemento={colunasSubelemento}
                    elementoControle={elementoControle}
                    setElementoControle={setElementoControle}
                  />
                ))}
                {/* Adicionar novo elemento */}
                <TableRow hover>
                  <TableCell
                    sx={{
                      borderLeft: "hidden",
                      borderBottom: "hidden",
                      borderTop: "hidden",
                    }}
                  ></TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox color="primary" disabled />
                  </TableCell>
                  <TableCell
                    colSpan={colunasElemento.length + 1}
                    sx={{ borderRight: "none" }}
                    align="left"
                  >
                    <Input
                      className="input-novo-elemento font-size-14"
                      placeholder="+ Adicionar Elemento"
                      maxLength="99"
                      onBlur={handleCriarElemento}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleCriarElemento(event);
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Row>
    </React.Fragment>
  );
}
