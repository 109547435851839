import PropTypes from "prop-types";
import React, { useEffect } from "react";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";

const Logout = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    api
      .get("/logout", { headers: { Authorization: token } })
      .then((response) => {})
      .catch((err) => {
        console.error("ops! ocorreu um erro " + err);
      });
    localStorage.clear();
    navigate("/login");
  });

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default Logout;
